import dayjs from 'dayjs'
import fr from 'dayjs/locale/fr'

const startOfMonth = (dt: dayjs.Dayjs) => {
  const d = dt.startOf('month')
  const diff = (7 + d.day() - fr.weekStart) % 7

  return d.subtract(diff, 'd')
}

const endOfMonth = (dt: dayjs.Dayjs) => {
  const d = dt.endOf('month')
  const weekEnd = (fr.weekStart + 6) % 7
  const diff = (weekEnd - d.day() + 7) % 7

  return d.add(diff, 'd')
}

export {
  startOfMonth,
  endOfMonth
}