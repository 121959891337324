<template>
  <el-config-provider :locale="elfr">
    <el-container>
      <el-header class="cra-express-header">
        <div class="cra-express-header__content">
          <NuxtLink class="cra-express-header__content__logo" :to="'/'">
            <SiteLogo class="cra-express-header__content__logo__img" />
            <SiteLogoTitle class="cra-express-header__content__logo__text"  />
          </NuxtLink>
          <el-dropdown placement="top-start">
            <div
              class="cra-express-header__content__user"
              @click="openValidationDialog"
            >
              <div class="cra-express-header__content__user__info">
                <template v-if="siteIdentity.identity.value && !siteIdentity.identity.value.isTemp">
                  <div>{{ siteIdentity.identity.value.name }}</div>
                  <div>{{ siteIdentity.identity.value.email }}</div>
                </template>
                <template v-else>
                  <div>Utilisateur anonyme</div>
                  <div>Email non renseigné</div>
                </template>
              </div>
              <div class="cra-express-header__content__user__avatar">
                <el-avatar
                  shape="square"
                  src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"
                />
              </div>
            </div>
            <template v-if="isAuthenticated && siteIdentity.identity.value && !siteIdentity.identity.value.isTemp" #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  @click="goToApp"
                >
                  Aller sur l'application
                </el-dropdown-item>
                <el-dropdown-item
                  @click="goToCraList"
                >
                  Voir la liste des CRAs
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </el-header>
      <el-main>
        <div class="cra-express">
          <div class="cra-express__content">
            <h1>Créer un compte rendu d'activité</h1>
            <div class="cra-express__content__detail">
              <div class="cra-express__content__detail__block-info">
                <h2 class="cra-express__content__detail__block-info__title">
                  CRA du mois de&nbsp;<el-card
                    shadow="hover"
                    class="cra-express__content__detail__block-info__title__month"
                    :body-style="{
                      padding: '3px'
                    }"
                  >
                    <el-date-picker
                      size='small'
                      v-model="referenceDateDate"
                      type="month"
                      :disabled-date="disabledDate"
                      format="MMMM YYYY"
                      :editable="false"
                      :clearable="false"
                      v-if="mounted"
                    />
                    <div v-else>
                      {{ referenceDate.format('MMMM YYYY') }}
                    </div>
                  </el-card>
                </h2>
                <h2>Informations sur la mission</h2>
                <el-card
                  shadow="hover"
                  class="cra-express__content__detail__block-info__mission"
                  :body-style="{
                    padding: '10px'
                  }"
                >
                  <el-popover
                    placement="top-start"
                    trigger="hover"
                    content="faire défiler les missions"
                    v-if="missions?.length && missions?.length > 1"
                  >
                    <template #reference>
                      <el-button
                        circle
                        size="large"
                        text
                        bg
                        class="cra-express__content__detail__block-info__mission__icon"
                        @click="incrementMission"
                      >
                        <VsxIcon iconName="RecoveryConvert" :size="22" color="rgb(1, 73, 139)" type="linear" />
                      </el-button>
                    </template>
                  </el-popover>
                  
                  <template v-if="currentMission">
                    <div
                      v-if="currentMission.label"
                      class="cra-express__content__detail__block-info__mission__line"
                    >
                      <div
                        class="cra-express__content__detail__block-info__mission__line__icon"
                      ><VsxIcon iconName="Tag" :size="20" type="bold" /></div>
                      {{ currentMission.label }}
                    </div>
                    <div class="cra-express__content__detail__block-info__mission__line">
                      <div
                        class="cra-express__content__detail__block-info__mission__line__icon"
                      ><VsxIcon iconName="Profile" :size="20" type="bold" /></div>
                      {{ currentMission.resource?.name }}
                    </div>
                    <div
                      v-if="currentMission.clientFinal"
                      class="cra-express__content__detail__block-info__mission__line"
                    >
                      <div
                        class="cra-express__content__detail__block-info__mission__line__icon"
                      ><VsxIcon iconName="Bank" :size="20" type="bold" /></div>
                      {{ currentMission.clientFinal }}
                    </div>
                    <div class="cra-express__content__detail__block-info__mission__line">
                      <div
                        class="cra-express__content__detail__block-info__mission__line__icon"
                      >
                        <VsxIcon iconName="PathTool" :size="20" type="bulk" />
                      </div>
                      <div>
                        <template v-if="currentMission.noCraValidation">
                          Pas de validation par le client
                        </template>
                        <template v-else>
                          Nécessite validation<br>
                          <div class="cra-express__content__detail__block-info__mission__line__valideur">
                            {{ currentMission.clientFinalValideurName }}<br>
                            {{ currentMission.clientFinalValideurEmail }}
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                  <div v-else-if="isAuthenticated && siteIdentity.identity.value" class="cra-express__content__detail__block-info__mission__no-mission">
                    <el-button @click="createUserAndShowCraDetail">
                      J'entre le détail de ma mission <VsxIcon class="el-icon--right" iconName="Edit" :size="18" type="linear" />
                    </el-button>
                  </div>
                  <div v-else-if="me" class="cra-express__content__detail__block-info__mission__no-mission">
                    Pour récupérer mes infos de mission
                    <el-button @click="showLogin = true">
                      Je m'authentifie
                    </el-button>
                  </div>
                  <div v-else class="cra-express__content__detail__block-info__mission__no-mission">
                    <el-button @click="showLogin = true">
                      Je m'authentifie
                    </el-button>
                    <div>ou</div>
                    <el-button @click="createUserAndShowCraDetail">
                      J'entre le détail de ma mission <VsxIcon class="el-icon--right" iconName="Edit" :size="18" type="linear" />
                    </el-button>
                  </div>
                </el-card>
                <h2>Remarques</h2>
                <div>
                  <el-input
                    class="cra-express__content__detail__block-info__comment"
                    type="textarea"
                    :disabled="!!currentCra"
                    :autosize="{ minRows: 4, maxRows: 4}"
                    placeholder="Ajoutez des précisions sur votre mois de mission si nécessaire"
                    v-model="comment"
                  />
                </div>
              </div>
              <div class="cra-express__content__detail__block-time">
                <div class="cra-express__content__detail__block-time__content">
                  <el-card
                    class="cra-express__content__detail__block-time__content__calendar"
                    :body-style="{
                      padding: 0
                    }"
                    shadow="hover"
                  >
                    <div class="cra-express__content__detail__block-time__content__calendar__days">
                      <div
                        class="cra-express__content__detail__block-time__content__calendar__days__day"
                        v-for="d in weekdaysKeys"
                        :key="fr.weekdays && fr.weekdays[d]"
                      >
                        {{ fr.weekdaysShort && fr.weekdaysShort[d] }}
                      </div>
                    </div>
                    <div
                      class="cra-express__content__detail__block-time__content__calendar__content"
                      :style="{
                        'grid-template-rows': [...Array(calendar.length / 7).keys()].map(r => '70px').join(' ')
                      }"
                    >
                      <div
                        class="cra-express__content__detail__block-time__content__calendar__content__cell"
                        v-for="(obj, index) in calendar"
                      >
                        <el-button
                          class="cra-express__content__detail__block-time__content__calendar__content__cell__inner"
                          :disabled="!!currentCra"
                          :class="{
                            full: obj.value === 1,
                            demi: obj.value === 0.5,
                            outOfRange: obj.outOfRange,
                            off: !obj.outOfRange && obj.off
                          }"
                          @click="nextState(obj)"
                        >
                          <div class="cra-express__content__detail__block-time__content__calendar__content__cell__inner__top">
                            <div>{{ obj.d.format('D') }}</div>
                          </div>
                          <div class="cra-express__content__detail__block-time__content__calendar__content__cell__inner__bottom">
                            <span v-if="obj.value === 1">1&thinsp;j.</span>
                            <span v-else-if="obj.value === 0.5">1/2&thinsp;j.</span>
                            <span v-else>-</span>
                          </div>
                        </el-button>
                      </div>
                    </div>
                  </el-card>
                  <div class="cra-express__content__detail__block-time__content__actions">
                    <el-button
                      size="small"
                      :disabled="!!currentCra"
                      @click="fillAll"
                    >Remplir tous les jours ouvrés</el-button>
                    <el-button
                      size="small"
                      :disabled="!!currentCra"
                      @click="resetCraDays"
                    >Vider le CRA</el-button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="currentCra" class="cra-express__content__detail__actions">
              <el-tag
                type="primary"
                v-if="!currentCra?.validated || formatCRADate(currentCra?.validated) === formatCRADate(currentCra?.date)"
              >
                CRA créé le {{ formatCRADate(currentCra?.date) }}
              </el-tag>
              <el-tag type="primary" v-else>
                CRA créé le {{ formatCRADate(currentCra?.date) }} et validé le {{ formatCRADate(currentCra?.validated) }}
              </el-tag>
            </div>
            <div
              v-if="!currentCra"
              class="cra-express__content__detail__actions"
            >
              <el-button
                type="primary"
                :disabled="!currentMission"
                @click="appearCreateCRA"
              >
                <span v-if="currentMission && currentMission.noCraValidation">Générer le CRA</span>
                <span v-else>Créer le CRA</span>
              </el-button>
            </div>
            <div
              v-else
              class="cra-express__content__detail__actions"
            >
              <el-button v-if="!currentCra.hasBill">
                <span @click="confirmRemoveCra">Supprimer</span>
              </el-button>
              <template v-if="currentCra.validated">
                <el-button
                  type="primary"
                  @click="downloadCra"
                >
                  <span>Télécharger</span>
                </el-button>
                <el-button
                  type="primary"
                  @click="goToInvoiceIfNotTemp"
                >
                  <span v-if="!currentCra.hasBill">Facturer</span>
                  <span v-else>Voir les factures</span>
                </el-button>
              </template>
              <template v-else>
                <el-button
                  type="primary"
                  @click="appearValidateCRA"
                >
                  <span>Faire valider</span>
                </el-button>
              </template>   
            </div>
          </div>
        </div>
      </el-main>
    </el-container>
    <SiteOverlay />
    <SiteSignUp
      v-model="showSignUp"
      :firstname="siteIdentity.identity.value?.firstname"
      :lastname="siteIdentity.identity.value?.lastname"
      @authenticated="afterAuthent"
    />
    <SiteLogin
      v-model="showLogin"
      @authenticated="afterAuthent"
    />
    <CraMission
      v-model="showCraDetail"
      @refresh-mission="refreshMission"
    />
    <el-dialog
      width="80%"
      v-model="createCRADialog"
    >
      <template #header>
        Création de CRA
      </template>
      <div class="cra-express-create">
        <span>Pour créer ce CRA avec <b>{{ nbDaysCra }}&nbsp;jour<template v-if="nbDaysCra > 1">s</template></b>, signez ci-dessous</span>
        <div class="cra-express-create__signature">
          <canvas
            class="cra-express-create__signature__img"
            ref="signatureCanvas"
          />
          <div class="cra-express-create__signature__actions">
            <el-button v-if="!signature" circle size="large" @click="emptySignaturePad">
              <VsxIcon iconName="Eraser" :size="24" type="linear" />
            </el-button>
          </div>
        </div>
        <el-switch
          class="cra-express-create__signature__ask"
          v-if="askSignatureSaving"
          v-model="signatureSaving"
          :disabled="signatureEmpty"
          active-text="Enregistrer ma signature pour la prochaine fois"
        /> 
      </div>
      <template #footer>
        <el-button @click="closeCreateCRAdialog">
          Annuler
        </el-button>
        <el-button
          type="primary"
          :loading="creatingCra"
          :disabled="signatureEmpty"
          @click="createCra"
        >
          Créer le CRA
        </el-button>
      </template>
    </el-dialog>
    <el-dialog
      v-model="validateCRADialog"
    >
      <template #header>
        Validation de CRA
      </template>
      <div
        class="cra-express-validate"
      >
        <p>Envoyez par mail la demande de signature du rapport à {{ currentCra?.mission.clientFinalValideurEmail }}</p>
        <el-button
          type="primary"
          @click="sendValidationMail"
        >
          Envoyer le mail
        </el-button>
        <p>Vous pouvez alternativement mettre en presse papier un lien à lui transmettre par le moyen de votre choix</p>
        <el-button
          type="primary"
          @click="copyToClipboard"
        >
          Mettre le lien dans le presse papier
        </el-button>
      </div>
      <template #footer>
        <el-button @click="closeValidateCRAdialog">
          Fermer
        </el-button>
      </template>
    </el-dialog>
  </el-config-provider>
  <GlobalEvents
    target="window"
    @resize="majCanvas"
  />
</template>

<script setup lang="ts">
  import useIdentity from '@/components/SiteIdentity'
  import dayjs from 'dayjs'
  import fr from 'dayjs/locale/fr'
  import elfr from 'element-plus/dist/locale/fr.mjs'
  import SiteLogo from '@/components/SiteLogo'
  import SiteLogoTitle from '@/components/SiteLogoTitle'
  import SiteOverlay from '@/components/SiteOverlay'
  import SiteSignUp from '@/components/SiteSignUp'
  import SiteLogin from '@/components/SiteLogin'
  import CraMission from '@/components/CraMission'
  import { VsxIcon } from "vue-iconsax";
  import { startOfMonth, endOfMonth } from 'assets/js/calendar'
  import SignaturePad from 'signature_pad'
  import { GlobalEvents } from 'vue-global-events'
  import { ElMessage } from 'element-plus'

  const mounted = ref(false)
  const showSignUp = ref(false)
  const showLogin = ref(false)
  const showCraDetail = ref(false)
  const siteIdentity = useIdentity()
  const config = useRuntimeConfig()
  
  const createCRADialog = ref(false)
  const validateCRADialog = ref(false)

  interface mission {
    client: {
      id: number,
      name: string,
      hasBillingMail: boolean,
      noCraInBill: boolean
    },
    label: string,
    clientFinal: string
    clientFinalValideurEmail?: string
    clientFinalValideurName?: string
    clientId: number
    esnId: number,
    generateInvoiceBeforeCRAValidation: boolean,
    id: number,
    noCraValidation: boolean,
    paymentDelay: number,
    provision: null,
    resource: {
      id: number,
      name: string,
      esnId: number,
      type: number
    },
    resourceId: number,
    tjm: number
  }

  interface cra {
    comment: string
    date: string
    days: {
      date: string
      val: number
    }[]
    hasBill: boolean
    id: number
    mission: mission
    missionId: number
    pdfId: string
    refMonth: string
    resource: {
      name: string,
      type: number,
      esnId: number,
      id: number
    }
    responsible: {
      id: number
      name: string,
      companyIds: number[],
      firstname: string,
      lastname: string
    }
    responsibleSign: null
    uuid: string
    validated: string
  }

  interface CalendarItem {
    d: dayjs.Dayjs
    outOfRange: boolean
    value: number
    off: boolean
  }

  const me = ref(null)
  const missions = ref<null | mission[]>(null)
  const cras = ref<cra[] | null>(null)

  const isAuthenticated = ref(false)

  const creatingCra = ref(false)

  const signature = ref<string | undefined>(undefined)
  const signatureCanvas = ref(null)

  const signaturePad = ref<SignaturePad | null>(null)
  const signatureEmpty = ref(true)

  const askSignatureSaving = ref(false)
  const signatureSaving = ref(true)

  dayjs.locale('fr')

  const res = await useFetch(`${config.public.apiBaseUrl}/offs`,{
    params: {
      year: dayjs().format('YYYY')
    }
  })

  onMounted(() => mounted.value = true)
  const offs = res.data.value

  const comment = ref<string | null>(null)
  const referenceDate = ref(dayjs().add(-8, 'd'))

  const referenceDateDate = computed({
    get () {
      return referenceDate.value.toDate()
    },
    set (value) {
      referenceDate.value = dayjs(value)
      reset()
    }
  })

  const refMonth = computed(() => {
    return dayjs(referenceDate.value).format('YYYY-MM')
  })

  const disabledDate = (d: dayjs.Dayjs) => {
    if (dayjs(d).add(12, 'month').diff(dayjs()) < 0) {
      return true
    } else if (dayjs().diff(dayjs(d).subtract(3, 'month')) < 0)  {
      return true
    }
  }

  const missionIndex = ref(0)

  const weekdaysKeys = [...Array(7).keys()].map(k => (k + fr.weekStart) % 7)

  const start = computed(() => startOfMonth(referenceDate.value))

  const end = computed(() => endOfMonth(referenceDate.value))

  const month = computed(() => referenceDate.value.month())

  const nbDays = computed(() => end.value.diff(start.value, 'day') + 1)

  const currentMission = computed<Partial<mission> | null>(() => {
    if (missions.value && missions.value.length) {
      const mission: mission = missions.value[missionIndex.value]
      return {
        id: mission.id,
        resource: mission.resource,
        client: mission.client,
        label: mission.label,
        clientFinal: mission.clientFinal,
        noCraValidation: mission.noCraValidation,
        clientFinalValideurName: mission.clientFinalValideurName,
        clientFinalValideurEmail: mission.clientFinalValideurEmail
      }
    } else {
      return null
    }
  })

  const incrementMission = () => {
    if (missions.value) {
      missionIndex.value = (missionIndex.value + 1) % missions.value.length
    }
  }

  const currentCra = computed(() => {
    return cras.value?.find(c => {
      return c.refMonth === refMonth.value && c.missionId === currentMission.value?.id
    })
  })

  const formatCRADate = (date: dayjs.Dayjs) => {
    return dayjs(date).format('D MMMM YYYY à HH:mm')
  }
  

  const days = ref<{
    date: string,
    val: number
  }[]>([])

  const refreshMission = async () => {
    await refreshMissions()
    showCraDetail.value = false
  }

  const calendar = computed<CalendarItem[]>(() => {
    return [...Array(nbDays.value).keys()].map(i => {
      const d = start.value.add(i, 'day')

      const corresponding = days.value?.find(day => day.date === d.format('YYYY-MM-DD'))
      
      return {
        d,
        outOfRange: month.value !== d.month(),
        value: corresponding ? corresponding.val : 0,
        off: (d.day() === (fr.weekStart + 6) % 7) ||  (d.day() === (fr.weekStart + 5) % 7 || offs.find(o => o.date === d.format('YYYY-MM-DD')))
      }
    })
  })


  const resetCraDays = () => {
    days.value = []
    calendar.value.filter(d => !d.outOfRange).forEach(d => {
      days.value.push({
        date: d.d.format('YYYY-MM-DD'),
        val: 0
      })
    })
  }

  const fillAll = () => {
    days.value = []
    calendar.value.filter(d => !d.outOfRange).forEach(d => {
      days.value.push({
        date: d.d.format('YYYY-MM-DD'),
        val: !d.off ? 1 : 0
      })
    })
  }

  const resetCraInfo = () => {
    comment.value = null
  }

  const reset = () => {
    resetCraInfo()
    if (currentCra.value) {
      days.value = []
      currentCra.value.days.forEach(d => {
        const existingDay = days.value.find(day => day.date === d.date)

        if (existingDay) {
          existingDay.val = d.val
        } else {
          days.value.push({
            date: d.date,
            val: d.val
          })
        }
      })
      comment.value = currentCra.value.comment
    } else {
      resetCraDays()
    }
  }

  const nbDaysCra = computed(() => {
    return days.value.map(d => {
      return d.val
    }).reduce((memo, val) => memo + val, 0)
  })

  const openValidationDialog = () => {
     if (siteIdentity.identity.value && siteIdentity.identity.value.isTemp) {
      showSignUp.value = true
    }
  }

  useSeoMeta({
    title: 'CRA Expresss | Générer un Compte Rendu d\'Activité (CRA) en ligne rapide et gratuit',
    ogSiteName: 'CRA Express',
    ogTitle: 'CRA Express | Générer un Compte Rendu d\'Activité (CRA) en ligne rapide et gratuit',
    description: 'Avec CRA Express, vous générez rapidement votre Compte Rendu d\'Activité en ligne sans création de compte et gratuitement',
    ogDescription: 'Avec CRA Express, vous générez rapidement votre Compte Rendu d\'Activité en ligne sans création de compte et gratuitement',
    ogImage: '/android-chrome-512x512.png'
  })

  const directSignup = async () => {
    const response = await $fetch(`${config.public.apiBaseUrl}/the-fountain-companies`, {
      method: 'POST',
      body: {
        from: 'cra-express-page'
      }
    })

    siteIdentity.identify(response)

    await siteIdentity.authenticate()
  }

  const nextState = (obj: CalendarItem) => {
    const date = obj.d.format('YYYY-MM-DD')
    const existingDay = days.value.find(day => day.date === date)

    if (!obj.outOfRange) {
      if (!existingDay) {
        days.value.push({
          date,
          val: 1
        })
      } else if (!existingDay.val) {
        existingDay.val = 1
      } else if (obj.value === 1) {
        existingDay.val = 0.5
      } else {
        existingDay.val = 0
      }
    }
  }

  const refreshMissions = async () => {
    missions.value = await $fetch(`${config.public.apiBaseUrl}/missions?auth_token=${siteIdentity.authentity.value}`)
  }

  const loadCRAInformations = async () => {
    if (siteIdentity.identity.value && !siteIdentity.identity.value.companies.length) {
      throw new Error('No company error')
    }

    refreshMissions()
    cras.value = await $fetch(`${config.public.apiBaseUrl}/cras?auth_token=${siteIdentity.authentity.value}&esnId=mine`)
    reset()
  }

  await siteIdentity.synchronizeAuthAndIdent(true, false)

  if (siteIdentity.authentity.value) {
    await loadCRAInformations()
  }

  isAuthenticated.value = !!siteIdentity.authentity.value

  const createUserAndShowCraDetail = async () => {
    showCraDetail.value = true
    if (!siteIdentity.authentity.value) {
      await directSignup()
      await loadCRAInformations()
      isAuthenticated.value = true
    }
  }

  const afterAuthent = async () => {
    showLogin.value = false
    showSignUp.value = false
    await siteIdentity.synchronizeAuthAndIdent(true, true)
    isAuthenticated.value = true
    await loadCRAInformations()
  }

  const goToApp = () => {
    location.href = config.public.appUrl
  }

  const goToCraList = () => {
    location.href = `${config.public.appUrl}/cra`
  }

  const emptySignaturePad = () => {
    signaturePad.value?.clear()
    signatureEmpty.value = true
  }

  const touchSignaturePad = () => {
    signatureEmpty.value = !!signaturePad.value?.isEmpty()
    askSignatureSaving.value = true
  }

  const appearCreateCRA = async () => {
    await siteIdentity.synchronizeAuthAndIdent(false, true)
    createCRADialog.value = true
    await nextTick()
    if (signatureCanvas.value) {
      signaturePad.value = new SignaturePad(signatureCanvas.value);
      signaturePad.value.addEventListener('afterUpdateStroke', touchSignaturePad);
      emptySignaturePad()
      majCanvas()
      if (siteIdentity.identity.value && siteIdentity.identity.value.signature) {
        signaturePad.value?.fromDataURL(siteIdentity.identity.value.signature)
      } else {
        askSignatureSaving.value = true
      }
      signatureEmpty.value = signaturePad.value.isEmpty()
    }
  }

  const appearValidateCRA = () => {
    validateCRADialog.value = true
  }

  const majCanvas = () => {
    const temp = signaturePad.value?.toDataURL()
    
    if (signatureCanvas.value) {
      const ratio =  Math.max(window.devicePixelRatio || 1, 1);
      signatureCanvas.value.width = signatureCanvas.value.offsetWidth * ratio;
      signatureCanvas.value.height = signatureCanvas.value.offsetHeight * ratio;
      signatureCanvas.value.getContext("2d").scale(ratio, ratio);
    }
   
    if (signaturePad.value) {
      emptySignaturePad()
      if (temp) {
        signaturePad.value?.fromDataURL(temp)
      }
    }
  }

  const closeCreateCRAdialog = () => {
    signaturePad.value?.removeEventListener('afterUpdateStroke', touchSignaturePad);

    createCRADialog.value = false
    creatingCra.value = false

    askSignatureSaving.value = false
    signatureSaving.value = true
  }

  const closeValidateCRAdialog = () => {
    validateCRADialog.value = false
  }

  const sendValidationMail = async () => {

    await $fetch(`${config.public.apiBaseUrl}/cras/${currentCra.value?.id}/validation-mails?auth_token=${siteIdentity.authentity.value}`, {
      method: 'post',
      body: {
        validationMail: currentCra.value?.mission.clientFinalValideurEmail
      }
    })

    ElMessage({
      message: 'Le mail de demande de validation a bien été envoyé',
      type: 'success',
    })

    validateCRADialog.value = false
  }

  const createCra = async () => {
    if (!creatingCra.value) {
      creatingCra.value = true

      const signature = signaturePad.value?.toDataURL()
      const cra = {
        refMonth: refMonth.value,
        missionId: currentMission.value?.id,
        comment: comment.value,
        days: days.value,
        responsibleSign: signature,
      }
      
      await $fetch(`${config.public.apiBaseUrl}/cras?auth_token=${siteIdentity.authentity.value}`, {
        method: 'post',
        body: cra
      })

      if (signatureSaving.value) {
        $fetch(`${config.public.apiBaseUrl}/me?auth_token=${siteIdentity.authentity.value}`, {
          method: 'patch',
          body: {
            signature
          }
        })

      }

      setTimeout(async () => {
        await loadCRAInformations()

        // waiting for the PDF to be generated
        setTimeout(() => {
          ElMessage({
            message: 'Votre CRA a bien été créé',
            type: 'success',
          })

          closeCreateCRAdialog() 
        }, 2000) 
      }, 2000)
    }
  }

  const confirmRemoveCra = async () => {
    try {
      await ElMessageBox.confirm(
        !currentCra.value?.validated ? 'Etes-vous sûr(e) de vouloir supprimer ce CRA?' : `Ce CRA a déjà été validé par ${currentCra.value.mission.clientFinalValideurName}. Etes-vous sûr(e) de vouloir le supprimer ?`,
        'Suppression de CRA',
        {
          confirmButtonText: 'Supprimer',
          cancelButtonText: 'Annuler',
          type: 'warning',
        }
      )
      await $fetch(`${config.public.apiBaseUrl}/cras/${currentCra.value?.id}?auth_token=${siteIdentity.authentity.value}`, {
        method: 'delete'
      })

      ElMessage({
        message: 'Le CRA a bien été supprimé',
        type: 'success',
      })
      
      await loadCRAInformations()
      reset()
    } catch (e) {

    }
  }
  const downloadCra = () => {
    window.location.assign(`${config.public.apiBaseUrl}/cras/${currentCra.value?.id}/pdf?auth_token=${siteIdentity.authentity.value}`);
  }

  const copyToClipboard = () => {
    const craUrl = config.public.appUrl + '/cra/validate/' + currentCra.value?.uuid

    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      return navigator.clipboard.writeText(craUrl)
    }
    return Promise.reject('The Clipboard API is not available.');
  }

  const goToInvoiceIfNotTemp = () => {
    if (siteIdentity.identity.value && !siteIdentity.identity.value.isTemp) {
      location.href = `${config.public.appUrl}/billing/client`
    } else {
      openValidationDialog()
    }
  }
</script>

<style lang="scss">
  $desktop-breakpoint: 990px;
  $tablet-breakpoint: 672px;

  .cra-express-header {
    color: rgb(96, 98, 102);
    display: flex;
    align-items: center;
    justify-content: center;

    &__content {
      max-width: 996px;
      flex-grow: 10;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__logo {
        display: flex;
        align-items: center;
        text-decoration: none;
        gap: 10px;

        .site-logo-title__main {
          font-size: 18.5px;
          line-height: 21px;
        }

        .site-logo-title__sub {
          font-size: 6.75px;
          line-height: 9px;
          letter-spacing: 2px;
        }

        &__text {
          display: none;
          @media screen and (min-width:500px) {
            display: block;
          }

        }
      }
      &__user {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        padding: 5px 5px 5px 10px;
        cursor: pointer;

        &__info {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
          font-size: 12px;
          line-height: 16px;
        }

        &__avatar {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .cra-express {
    color: rgb(1, 73, 139);
    display: flex;
    justify-content: center;
    
    &__content {
      max-width: 996px;
      flex-grow: 10;

      h1 {
        margin-top: 0;
        color: rgb(1, 73, 139);
      }

      h2 {
        font-weight: 400;
        font-size: 22px;
      }

      &__detail {
        display: flex;
        flex-direction: column;
        gap: 35px;

        @media screen and (min-width: $tablet-breakpoint) {
          flex-direction: row;
        }

        &__block-info {
          flex-shrink: 3;
          flex-grow: 10;
          @media screen and (min-width: $tablet-breakpoint) {
            max-width: 50%;
          }

          &__mission {
            position: relative;
            &__icon {
              position: absolute;
              right: 10px;
              top: 7px;
            }
            &__line {
              display: flex;
              align-items: flex-start;
              line-height: 30px;
              gap: 7px;

              &__icon {
                height: 30px;
                line-height: 30px;
                display: flex;
                align-items: center;
              }

              &__valideur {
                font-size: 14px;
                line-height: 21px;
                color: grey;
              }
            }

            &__no-mission {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 10px;
            }
          }

          &__comment {
            textarea {
              resize: none;
            }
          }

          &__title {
            display: flex;
            align-items: baseline;
            &__month {
              padding: 0;
              text-transform: capitalize;
              font-weight: 600;
              display: inline-block;
              color: rgb(48, 49, 51);
              display: flex;
              align-items: baseline;

              .el-date-editor.el-input {
                width: initial;
                height: initial;
                display: flex;
                align-items: baseline;
              }
              .el-input__wrapper {
                border: none;
                padding: 0;
                box-shadow: none;
                display: flex;
                align-items: baseline;
                .el-input__prefix {
                  display: none;
                  
                }
              }

              .el-input__inner {
                width: 170px;
                display: flex;
                align-items: baseline;
              }

              input {
                flex-basis:content;
                font-weight: 600;
                text-transform: capitalize;
                width: 150px;
                color: rgb(48, 49, 51);
                font-size: 22px;
                font-family: "Outfit", sans-serif;
                display: flex;
                align-items: baseline;
              }
            }
          }
        }
        
        &__block-time {
          flex-shrink: 1;
          flex-grow: 10;
          display: flex;
          justify-content: center;
          @media screen and (min-width: $tablet-breakpoint) {
            max-width: 50%;
          }

          &__content {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            flex-grow: 10;
            gap: 10px;
            max-width: 400px;
            @media screen and (min-width: $tablet-breakpoint) {
              max-width: none;
            }

            &__calendar {
              border-radius: 10px;

              display: flex;
              flex-direction: column;

              &__days {
                display: flex;
                flex-direction: row;
                justify-content: stretch;
                background-color: rgba(153,183,208, 0.125);
                &__day {
                  flex-grow: 10;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 7px;
                }
              }

              &__content {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

                &__cell {
                  border-top: 1px solid rgba(153,183,208, 0.125);
                  padding: 5px;
                  display: flex;
                  align-items: stretch;
                  justify-content: stretch;
                  user-select: none;

                  &__inner {
                    border: none;
                    background-color: white;;
                    border-radius: 5px;
                    display: block;
                    justify-content: stretch;
                    align-items: stretch;
                    flex-grow: 10;
                    height: auto;
                    cursor: pointer;
                    padding: 0;
                    transition: background-color 0.1s, color 0.1s;

                    > span {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      flex-grow: 10;
                    }

                    &.off {
                      background-color: rgba(153,183,208, 0.125);
                      &[disabled] {
                        background-color: rgba(153,183,208, 0.125);
                      }
                    }

                    &:hover {
                      background-color: rgba(1, 73, 139, 0.75);
                    }

                    &.demi {
                      background-color: rgb(153,183,208);
                      color: white;
                      &:hover {
                        background-color: rgba(1, 73, 139, 0.45);
                      }
                      &[disabled] {
                        background-color: rgba(153,183,208, 0.5);
                        color: white;
                      }
                    }

                    &.full {
                      background-color: rgb(1, 73, 139);
                      color: white;
                      &:hover {
                        background-color: rgba(1, 73, 139, 0.8);
                      }
                      &[disabled] {
                        color: white;
                        background-color: rgba(1, 73, 139, 0.4);
                      }
                    }

                    &.outOfRange {
                      cursor: default;
                      background-color: white;
                      color: rgba(153,183,208, 0.125);
                      &:hover {
                        background-color: white;
                        color: rgba(153,183,208, 0.125);
                      }
                    }

                    &__top {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }

                    &__bottom {
                      font-size: 12px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }
                }
              }
            }

            &__actions {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
            }
          }
        }

        &__actions {
          margin-top: 20px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }

  .cra-express-create {

    display: flex;
    flex-direction: column;

    &__signature {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: stretch;
      border: 2px dashed rgb(96, 98, 102);
      border-radius: 10px;
      position: relative;
      user-select: none;
      max-width: 500px;
      width: 100%;
      aspect-ratio: 3;


      &__img {
        width: 100%;
        user-drag: none;
        user-select: none;
      }

      &__actions {
        position: absolute;
        bottom: 0;
        transform: translateY(50%);
        right: 20px;
      }

      &__ask {
        align-self: center;
      }
    }
  }

</style>