<template>
  <SitePopup
    :visible="props.modelValue"
    title="Détail de la mission"
    @close="emit('update:model-value', false)"
  >
    <SitePopupContent
      @done="emit('refresh-mission')"
    />
  </SitePopup>
</template>

<script setup>
  const props = defineProps({
    modelValue: Boolean
  })
  const emit = defineEmits(['update:model-value', 'refresh-mission'])

  const SitePopupContent = defineAsyncComponent(() => import('./Content'))
</script>